import React from 'react';

import { SectionBanner, SectionContent, SectionDisclaimer, SectionForm, SectionInfo, SectionIntro, SectionPrivacy } from '../components/Section';

function PageDisclaimer(props)
{
    return (
        <React.Fragment>
            <SectionDisclaimer
                debug={props.debug}
                ip_address={props.ip_address}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />
        </React.Fragment>
    );
}

function PageHomepage(props)
{
    return (
        <React.Fragment>
            <SectionBanner
                debug={props.debug}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />

            <SectionIntro
                debug={props.debug}
                ip_address={props.ip_address}
                translation_data={props.translation_data}
                translation_language={props.translation_language} /> 

            <SectionInfo
                debug={props.debug}
                translation_data={props.translation_data}
                translation_language={props.translation_language} /> 

            <SectionContent
                debug={props.debug}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />

            <SectionForm
                debug={props.debug}
                ip_address={props.ip_address}
                routes_urls={props.routes_urls}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />
        </React.Fragment>
    );
}

function PagePrivacy(props)
{
    return (
        <React.Fragment>
            <SectionPrivacy
                debug={props.debug}
                ip_address={props.ip_address}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />
        </React.Fragment>
    );
}

export { PageDisclaimer, PageHomepage, PagePrivacy };
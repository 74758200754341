import React, { useState, useEffect, useRef } from 'react';

// Props: none
function ElementDebugResponsive()
{
    return (
        <div className="debug-responsive-container">
            <div className="d-block d-sm-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">xs</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8010/backend/architectuurvisualisatie/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-sm-block d-md-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">sm</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8010/backend/architectuurvisualisatie/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-md-block d-lg-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">md</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8010/backend/architectuurvisualisatie/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-lg-block d-xl-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">lg</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8010/backend/architectuurvisualisatie/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-xl-block d-xxl-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">xl</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8010/backend/architectuurvisualisatie/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-xxl-block">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <span className="debug-responsive-text">xxl</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8010/backend/architectuurvisualisatie/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
        </div>
    );
}

// Props: slideshow
function ElementSlideshow(props)
{
    // Slideshow data
    const [slideshow_current, slideshow_current_set] = useState(0);
    const [slideshow_previous, slideshow_previous_set] = useState(0);

    // Slideshow interval
    let slideshow_interval = useRef(null);

    useEffect(() => {
        slideshow_interval.current = setInterval(() => {
            clearInterval(slideshow_interval.current);

            let slideshow_new = (slideshow_current+1) % props.slideshow.length;
            slideshow_previous_set(slideshow_current);
            slideshow_current_set(slideshow_new);
        }, 12000);
    }, [slideshow_current, props.slideshow.length]);

    const setSlide = (slideshow_new) => {
        clearInterval(slideshow_interval.current);

        slideshow_previous_set(slideshow_current);
        slideshow_current_set(slideshow_new);
    };

    // Thumbnail slideshow
    const thumbnails_foreground = [];
    const thumbnails_background = [];
    for (let i = 0; i < props.slideshow.length; i++)
    {
        thumbnails_foreground.push(
            <div key={i} className="slideshow-thumbnail-flex" onClick={() => setSlide(i)}>
                <ElementSlideshowThumbnail
                    selected={i === slideshow_current}
                    title={props.slideshow[i][0]} />
            </div>
        );

        thumbnails_background.push(
            <div key={i} className="slideshow-thumbnail-flex">
                <ElementSlideshowThumbnail
                    selected={i === slideshow_current}
                    title={props.slideshow[i][0]} />
            </div>
        );
    }

    return (
        <section className="slideshow-section">
            { props.slideshow.length > 0 &&
                <React.Fragment>
                    <div className={'slideshow-container-overlay '+props.slideshow[slideshow_current][1]+' slideshow-fade-'+(1+slideshow_current)}>
                        <div className={'slideshow-thumbnail-gradient'}>
                            <div className="container">
                                <div className="slideshow-thumbnail-container">
                                    {thumbnails_foreground}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'slideshow-container '+props.slideshow[slideshow_previous][1]}>
                        <div className={'slideshow-thumbnail-gradient'}>
                            <div className="container">
                                <div className="slideshow-thumbnail-container">
                                    {thumbnails_background}
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            }
        </section>
    );
}

// Props: selected, title
function ElementSlideshowThumbnail(props)
{
    let style = {};
    if (props.selected)
    {
        style = {
            color: '#ffffff'
        }
    }
    else
    {
        style = {
            color: '#939393'
        }
    }
    
    return (
        <div className="slideshow-thumbnail" style={style}>
            { props.selected === true &&
                <div className={'slideshow-thumbnail-timer'}>
                    <div className={'slideshow-thumbnail-timer-animation'}></div>
                </div>
            }

            { props.selected === false &&
                <div className={'slideshow-thumbnail-timer'}>
                </div>
            }

            <div className="d-none d-lg-block">
                <div className="slideshow-thumbnail-title">
                    {props.title}
                </div>
            </div>
        </div>
    );
}

// Props: text
function ElementTitleH1(props)
{
    return (
        <React.Fragment>
            <div className="d-block d-md-none">
                <h1 className="element-title-h1-mobile">{props.text}</h1>
            </div>
            <div className="d-none d-md-block">
                <h1 className="element-title-h1-desktop">{props.text}</h1>
            </div>
        </React.Fragment>
    );
}

// Props: text
function ElementTitleH2(props)
{
    return (
        <React.Fragment>
            <div className="d-block d-md-none">
                <h2 className="element-title-h2-mobile">{props.text}</h2>
            </div>
            <div className="d-none d-md-block">
                <h2 className="element-title-h2-desktop">{props.text}</h2>
            </div>
        </React.Fragment>
    );
}

export { ElementDebugResponsive, ElementSlideshow, ElementSlideshowThumbnail, ElementTitleH1, ElementTitleH2 };